<script lang="ts">
	import config from 'src/config'

	const weekday = new Date().toLocaleString('en-us', { weekday: 'long' })

	const now = new Date()

	// Format it using Intl.DateTimeFormat to Eastern Time Zone
	const easternTime = new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'America/New_York',
	}).format(now)

	const hours = config.hours[weekday]
	const [month, day, year] = easternTime.split('/')
	const dateInIso = `${year}-${month}-${day}`
	// const dateInIso = new Date().toISOString().split('T')[0]
	const useOverride = config.hourOverrides?.[dateInIso]
</script>

<div
	class="flex-1 items-center justify-center gap-6 text-white sm:justify-end lg:flex lg:w-1/4"
>
	{hours ? `${useOverride || hours}` : 'Closed today'}.
</div>
